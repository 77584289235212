import { type Resource, type ResourceWithChildren, type StateResource } from '@models/resource';
import { concat, forkJoin, merge, type MonoTypeOperatorFunction, Observable, of, Subject, timer, type Subscriber, NEVER } from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  expand,
  first,
  last,
  map,
  scan,
  shareReplay,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
  toArray,
} from 'rxjs/operators';
import { sort as doSort, sortByText } from './array';
import { DestroyRef, inject } from '@angular/core';

export const getById =
  (id: Id | null) =>
  <T extends Resource>(source: Observable<T[]>) =>
    source.pipe(map((m: T[]) => m.find(n => n.id === id)));

export const sort =
  <T extends Resource>(sortFnc?: (a: T, b: T) => number) =>
  (source: Observable<T[]>) =>
    source.pipe(
      map(m => {
        return doSort(m, sortFnc);
      })
    );

export const sortWithChildren =
  <U extends Resource, T extends ResourceWithChildren<U>>(sortFnc?: (a: T, b: T) => number) =>
  (source: Observable<T[]>) =>
    source.pipe(
      map(m => {
        return doSort(m, sortFnc);
      }),
      map(m => {
        if (m) {
          for (const item of m) {
            // Typescript is mean... this is valid
            if (item.children) {
              item.children = doSort(item.children as Resource[], sortFnc) as U[];
            }
          }
        }

        return m;
      })
    );

export const sortAlphabetically =
  () =>
  <T extends { text?: string }>(source: Observable<T[]>) =>
    source.pipe(map(m => m.sort(sortByText)));

export const getVisibleOnly =
  (forceId?: Id) =>
  <T extends StateResource>(source: Observable<T[]>) =>
    source.pipe(map((m: T[]) => m.filter(n => !n.hidden || n.id === forceId)));

export const series = function <T>(...observables: Observable<T>[]): Observable<T[]> {
  return concat(...observables.map(o => o.pipe(last()))).pipe(toArray());
};

function isCatcheableError<T>(err: unknown): err is { __customError: T } {
  return err && !!(err as any).__customError;
}

function createCatcheableError<T>(err: T): Observable<{ __customError: T }> {
  return of({
    __customError: err,
  });
}

export const runAll = function <T>(observables: Observable<T>[]): Observable<T[]> {
  return forkJoin(observables.map(observable => observable.pipe(catchError(e => createCatcheableError(e))))).pipe(
    defaultIfEmpty([]),
    map(results => {
      const errors = results?.filter(result => isCatcheableError(result)).map(result => result.__customError);

      if (errors?.length) {
        throw errors;
      }

      return results as T[];
    })
  );
};

export const allowNull =
  <T>() =>
  (source: Observable<T>) =>
    source.pipe(
      map(value => {
        return {
          isNull: value === null,
          isUndefined: value === undefined,
          value,
        };
      })
    );

export const userManagedLogic = function <T>(obs$: Observable<T>): Observable<T> & { next: (value: T) => void } {
  class UserManaged extends Observable<T> {
    private readonly subject$ = new Subject<T>();
    private readonly viewData$ = merge(this.subject$, obs$).pipe(shareReplay(1));

    constructor(public obs: Observable<T>) {
      super((subscriber: Subscriber<T>) => {
        return this.viewData$.subscribe(subscriber);
      });
    }

    next(value: T): void {
      this.subject$.next(value);
    }
  }

  return new UserManaged(obs$);
};

export const takeFirst = function <T>(obs$: Observable<T>): Observable<T> {
  return obs$.pipe(first());
};

export function pollWhile<T>(
  pollInterval: number,
  isPollingActive: (res: T) => boolean,
  destroy?: Observable<any>
): MonoTypeOperatorFunction<T> {
  return source$ =>
    source$.pipe(
      expand(() => timer(pollInterval).pipe(switchMap(() => source$))),
      takeUntil(destroy ?? NEVER),
      takeWhile(isPollingActive, true)
    );
}

export function injectDestroy() {
  const subject = new Subject();

  inject(DestroyRef).onDestroy(() => {
    subject.next(true);
    subject.complete();
  });

  return subject.asObservable();
}
